function extendUrlParams(
  toUrl: string,
  fromUrl = window.location.href,
  keys?: string[],
) {
  const toUrlUrl = new URL(toUrl);
  const fromSearchParams = new URL(fromUrl).searchParams;

  (keys ?? Array.from(fromSearchParams.keys())).forEach((key) => {
    const value = fromSearchParams.get(key);
    if (value) {
      toUrlUrl.searchParams.set(key, value);
    }
  });

  return toUrlUrl.href;
}

export { extendUrlParams };
