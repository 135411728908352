import { createArgusEvent } from "@/lib/argus/client/thickClient";
import { onSetCookie } from "@/lib/helpers/cookies";

function addInitEvent() {
  return createArgusEvent("init", { userAgent: window.navigator.userAgent });
}

function initCookieConsentListener() {
  onSetCookie(
    "solvariCookieConsent",
    (consentTypes) => void createArgusEvent("cookie_consent", { consentTypes }),
  );
}

export { addInitEvent, initCookieConsentListener };
