function getForm() {
  return document.querySelector<HTMLElement>("[data-tool='solvari-form']");
}

type FormProps = {
  abTests?: Record<string, unknown>;
  formGroupId?: number;
  formId?: number;
  formVariantName?: string;
};

function getFormProps(form: HTMLElement) {
  return form.dataset.props
    ? (JSON.parse(form.dataset.props) as FormProps)
    : {};
}

function getFormProp<Key extends keyof FormProps>(
  form: HTMLElement,
  prop: Key,
): FormProps[Key] {
  return getFormProps(form)[prop];
}

function redirectToUrl(url: string) {
  window.location.href = url;
}

export { getForm, getFormProp, redirectToUrl };
