/*
  The service handles backend communication and initializing and continuing the session.
  This should only run once on the page.
 */

import { v4 as createUuid } from "uuid";

import { initRootClient } from "@/lib/argus/client/rootClient";
import {
  applyArgusUuidToUrl,
  getSessionType,
  getSessionUuid,
  getSolvariClientId,
} from "@/lib/argus/client/thinClient";
import { setCookie } from "@/lib/helpers/cookies";
import { isBot, isSupportedBrowser } from "@/lib/helpers/isSupportedBrowser.ts";
import { sSessionStorage } from "@/lib/helpers/localStorage";
import { getEnv } from "@/lib/helpers/solvariEnv";
import { addSentryProject } from "@/lib/integrations/sentry/client";

async function initArgus() {
  if (isBot() || !isSupportedBrowser()) {
    return;
  }

  initClientId();

  const sessionType = getSessionType();

  if (sessionType === "cross-domain") {
    continueCrossDomainSession();
  }

  initCrossDomainLinker();

  if (getEnv().config.envFE !== "development") {
    addSentryProject({
      name: "argus-fe",
      path: null,
      environment: getEnv().config.envFE,
      // The DSN is the same for all projects
      dsn: "https://008983dfa3b1c397803a7469032e30d2@o1412996.ingest.us.sentry.io/4507572371390464",
    });
  }

  const sessionUuid = await initRootClient(sessionType).flush();
  if (sessionUuid && !(sessionUuid instanceof Error)) {
    storeSolvariSessionUuid(sessionUuid);
  }
}

function continueCrossDomainSession() {
  const urlParamSessionUuid = new URLSearchParams(window.location.search).get(
    "solvariSession",
  )!;
  storeSolvariSessionUuid(urlParamSessionUuid);
  new URLSearchParams(window.location.search).delete("solvariSession");
}

function initClientId() {
  storeSolvariClientId(
    new URLSearchParams(window.location.search).get("solvariClientId") ??
      getSolvariClientId() ??
      createUuid(),
  );
  new URLSearchParams(window.location.search).delete("solvariClientId");
}

function storeSolvariClientId(uuid: string) {
  setCookie({
    name: "solvariClientId",
    value: uuid,
    maxAge: "31536000",
  });
}

function storeSolvariSessionUuid(uuid: string) {
  sSessionStorage.setItem("solvariSession", uuid);
}

function initCrossDomainLinker() {
  document.addEventListener("click", (event) => {
    const anchorElement = (
      event.target as HTMLElement
    ).closest<HTMLAnchorElement>("a[href]");

    if (!anchorElement) {
      return;
    }

    anchorElement.href = applyArgusUuidToUrl(
      anchorElement.href,
      getSessionUuid(),
      getSolvariClientId(),
    );
  });
}

export { initArgus, storeSolvariClientId, storeSolvariSessionUuid };
