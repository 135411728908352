import { pipe } from "ramda";

import { getAbTestFeature } from "@/lib/ab-testing/client";
import { url } from "@/lib/ab-testing/service/attributes";
import { redirectToUrl } from "@/lib/ab-testing/service/helpers";
import { applyArgusUuidToUrl } from "@/lib/argus/client/thinClient";
import { extendUrlParams } from "@/lib/helpers/url";

function splitUrlFeature(redirect = redirectToUrl) {
  const splitUrl = getAbTestFeature<string>("split-url");
  if (!splitUrl) {
    return;
  }
  const currentPage = url();

  if (currentPage === splitUrl) {
    return;
  }
  pipe(applyArgusUuidToUrl, extendUrlParams, redirect)(splitUrl);
}

export { splitUrlFeature };
